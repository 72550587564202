import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import ArticleCard from '../../components/card/Article';
import PaginationBlock from '../../components/block/Pagination';

// Articles
const ArticlesPage = ({ pageContext, data }) => {
  const { totalCount } = data.articles
  const pageTitle = `Articles - ${totalCount} article${totalCount === 1 ? "" : "s"}`
  
  const headerBackgroundImageRandom = Math.floor(Math.random() * data.articles.nodes.length);
  const headerBackgroundImage = data.articles.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.articles.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={`Découvrez les articles de presse concernant ${data.site.siteMetadata.title}. Les Roannais parlent de nous.`}
      pageKeywords="pcc bâtiment, articles, presse, témoignages, avis, roanne, coteau, plâtrerie, peinture"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Nos articles</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Articles</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.articles && data.articles.nodes.length ?
        <section id="articles">
          <div className="container">
            <div className="section-title">
              <div className="row mb-40">
                <div className="col-sm-6">
                  <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">Tous les articles</span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                  <div className="tm-sc section-title section-title-style1 text-left">
                    <div className="title-wrapper">
                      <h2 className="title"> <span>Ils parlent de </span> <span className="text-theme-colored1">nous</span></h2>
                      <div className="title-seperator-line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-sm-15 mt-10 mb-0">
                    <p>Retrouvez dans cette rubrique tous les articles de presse concernant {data.site.siteMetadata.title}.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                {data.articles.nodes.map(article => (
                  <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                ))}
              </div>
            </div>
            <div>
              <PaginationBlock
                path={`articles`}
                currentPage={pageContext.currentPage}
                numPages={pageContext.numPages}
              />
            </div>
          </div>
        </section>
      : null}
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    articles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          publisher
          type
          link
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default ArticlesPage